import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardGroup,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Navbar,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { Card as BootstrapCard } from "react-bootstrap";
import "./Gallery.css";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import axios from "axios";
import image1 from "../../../images/lakshmipooja1.jpg";
import image2 from "../../../images/lakshmipooja2.jpg";
import image4 from "../../../images/lakshmipooja3.jpg";
import image5 from "../../../images/lakshmipooja4.jpg";
import enve1 from "../../../images/env1.JPG";
import enve2 from "../../../images/env2.JPG";
import enve3 from "../../../images/env3.JPG";
import enve4 from "../../../images/env4.JPG";
import enve5 from "../../../images/env5.JPG";
import enve6 from "../../../images/env6.JPG";
import enve7 from "../../../images/env7.JPG";
import sara1 from "../../../images/saraswathi pooja1.JPG";
import sara2 from "../../../images/saraswathi pooja2.JPG";
import sara3 from "../../../images/saraswathi pooja3.JPG";
import sara4 from "../../../images/saraswathi pooja4.JPG";
import sara5 from "../../../images/saraswathi pooja5.JPG";
import sara6 from "../../../images/saraswathi pooja6.JPG";
import sara7 from "../../../images/saraswathi pooja7.JPG";
import sara8 from "../../../images/saraswathi pooja8.JPG";
import sara9 from "../../../images/saraswathi pooja9.JPG";
import yoga1 from "../../../images/yoga1.JPG";
import yoga2 from "../../../images/yoga2.JPG";
import yoga3 from "../../../images/yoga3.JPG";
import yoga4 from "../../../images/yoga4.JPG";
import yoga5 from "../../../images/yoga5.JPG";
import yoga6 from "../../../images/yoga6.JPG";
import yoga7 from "../../../images/yoga7.JPG";
import yoga8 from "../../../images/yoga8.JPG";
import yoga9 from "../../../images/yoga9.jpeg";
import fre1 from "../../../images/fresherday1.JPG";
import fre2 from "../../../images/fresherday2.JPG";
import fre3 from  "../../../images/fresherday3.JPG";
import fre4 from "../../../images/fresherday4.JPG";
import fre5 from "../../../images/fresherday5.JPG";
import fre6 from "../../../images/fresherday6.JPG";
import fre7 from "../../../images/fresherday7.JPG";
import bday1 from "../../../images/birthday photo-1.JPG";
import bday2 from "../../../images/birthday photo-2.JPG";
import bday3 from "../../../images/birthday photo-3.JPG";
import bday4 from "../../../images/birthday photo-4.JPG";
import bday5 from "../../../images/birthday photo-5.JPG";
import bday6 from "../../../images/birthday photo-6.JPG";
import bday7 from "../../../images/birthday photo-7.JPG";
import bday8 from "../../../images/birthday photo-8.JPG";
import bday9 from "../../../images/birthday photo-9.JPG";
import bday10 from "../../../images/birthday photo-10.JPG";
import bday11 from "../../../images/birthday photo-11.JPG";
import bday12 from "../../../images/birthday photo-12.JPG";
import bday13 from "../../../images/birthday photo-13.JPG";
import bday14 from "../../../images/birthday photo-14.JPG";
import bday15 from "../../../images/birthday photo-15.JPG";
import bday16 from "../../../images/birthday photo-16.JPG";
import bday17 from "../../../images/birthday photo-17.JPG";
import gandhi1 from "../../../images/image_g1.JPG";
import gandhi2 from "../../../images/image_g2.JPG";
import gandhi3 from "../../../images/image_g3.JPG";
import gandhi4 from "../../../images/image_g4.JPG";
import gandhi5 from "../../../images/image_g5.JPG";
import race1 from "../../../images/Cross Country Race 2023/DSC_0204.JPG";
import race2 from "../../../images/Cross Country Race 2023/DSC_0208.JPG";
import race3 from "../../../images/Cross Country Race 2023/DSC_0209.JPG";
import race4 from "../../../images/Cross Country Race 2023/DSC_0209.JPG";
import race5 from "../../../images/Cross Country Race 2023/DSC_0267.JPG";
import race6 from "../../../images/Cross Country Race 2023/DSC_0302.JPG";
import race7 from "../../../images/Cross Country Race 2023/DSC_0337.JPG";
import race8 from "../../../images/Cross Country Race 2023/DSC_0358.JPG";
import race9 from "../../../images/Cross Country Race 2023/DSC_0378.JPG";
import race10 from "../../../images/Cross Country Race 2023/DSC_0448.JPG";
import race11 from "../../../images/Cross Country Race 2023/DSC_0526.JPG";
import race12 from "../../../images/Cross Country Race 2023/DSC_0555.JPG";
import hindhi1 from "../../../images/Hindi Diwas Celebration 2023/DSC_0020.JPG";
import hindhi2 from "../../../images/Hindi Diwas Celebration 2023/DSC_0026.JPG";
import hindhi3 from "../../../images/Hindi Diwas Celebration 2023/DSC_0029.JPG";
import hindhi4 from "../../../images/Hindi Diwas Celebration 2023/DSC_0038.JPG";
import hindhi5 from "../../../images/Hindi Diwas Celebration 2023/DSC_0041.JPG";
import hindhi_6 from "../../../images/Hindi Diwas Celebration 2023/DSC_0052.JPG";
import hindhi6 from "../../../images/Hindi Diwas Celebration 2023/DSC_0057.JPG";
import hindhi7 from "../../../images/Hindi Diwas Celebration 2023/DSC_0072.JPG";
import hindhi8 from "../../../images/Hindi Diwas Celebration 2023/DSC_0481.JPG";
import hindhi9 from "../../../images/Hindi Diwas Celebration 2023/DSC_0489.JPG";
import hindhi10 from "../../../images/Hindi Diwas Celebration 2023/DSC_0494.JPG";
import iday_1 from "../../../images/Independance Day 2023/DSC_0421.JPG";
import iday_2 from "../../../images/Independance Day 2023/DSC_0430.JPG";
import raksha_1 from "../../../images/Raksha Bhandhan 2023/DSC_0736.JPG";
import raksha_2 from "../../../images/Raksha Bhandhan 2023/DSC_0757.JPG";
import raksha_3 from "../../../images/Raksha Bhandhan 2023/DSC_0760.JPG";
import raksha_4 from "../../../images/Raksha Bhandhan 2023/DSC_0764.JPG";
import raksha_5 from "../../../images/Raksha Bhandhan 2023/DSC_0792.JPG";
import teach_1 from "../../../images/Teachers Day Celebration 2023/DSC_0750.JPG";
import teach_2 from "../../../images/Teachers Day Celebration 2023/DSC_0756.JPG";
import teach_3 from "../../../images/Teachers Day Celebration 2023/DSC_0757.JPG";
import teach_4 from "../../../images/Teachers Day Celebration 2023/DSC_0763.JPG";
import teach_5 from "../../../images/Teachers Day Celebration 2023/DSC_0771.JPG";
import teach_6 from "../../../images/Teachers Day Celebration 2023/DSC_0780.JPG";
import teach_7 from "../../../images/Teachers Day Celebration 2023/DSC_0783.JPG";
import teach_8 from "../../../images/Teachers Day Celebration 2023/DSC_0785.JPG";
import teach_9 from "../../../images/Teachers Day Celebration 2023/DSC_0801.JPG";
import teach_10 from "../../../images/Teachers Day Celebration 2023/DSC_0911.JPG";
import  raj1 from "../../../images/kannada1.JPG";
import raj2 from "../../../images/kannada2.JPG";
import raj3 from "../../../images/kannada3.JPG";
import raj4 from "../../../images/kannada4.JPG";





const Gallery = ({ label, image }) => {
  const [gallery, setGallery] = useState([]);
  const [eventTypeId, setEventTypeId] = useState(0);
  const [eventTypeName, setEventTypeName] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [eventData, setEventData] = useState([]);

  //photos of saraswathi pooja
  const group = [
    { id: 1, label: "Card 1", image: sara1 },
    { id: 2, label: "Card 2", image: sara2 },
    { id: 3, label: "Card 3", image: sara3 },
    { id: 4, label: "Card 4", image: sara4 },
    { id: 5, label: "Card 5", image: sara5 },
    { id: 6, label: "Card 6", image: sara6 },
    { id: 7, label: "Card 7", image: sara7 },
    { id: 8, label: "Card 8", image: sara8 },
    { id: 9, label: "Card 9", image: sara9 },
  ];

  //photos of yoga day 2023
  const yogaGroup = [
    { id: 1, label: "Card 1", image: yoga1 },
    { id: 2, label: "Card 2", image: yoga2 },
    { id: 3, label: "Card 3", image: yoga3 },
    { id: 4, label: "Card 4", image: yoga4 },
    { id: 5, label: "Card 5", image: yoga5 },
    { id: 6, label: "Card 6", image: yoga6 },
    { id: 7, label: "Card 7", image: yoga7 },
    { id: 8, label: "Card 8", image: yoga8 },
    { id: 9, label: "Card 9", image: yoga9 },
  ];

//123rd Birth Anniversary of Shri Sanganabasava Swamiji
const swamjiBirthday = [
  { id: 1, label: "Card 1", image: bday1 },
  { id: 2, label: "Card 2", image: bday2 },
  { id: 3, label: "Card 3", image: bday3 },
  { id: 4, label: "Card 4", image: bday4 },
  { id: 5, label: "Card 5", image: bday5 },
  { id: 6, label: "Card 6", image: bday6 },
  { id: 7, label: "Card 7", image: bday7 },
  { id: 8, label: "Card 8", image: bday8 },
  { id: 9, label: "Card 9", image: bday9 },
  { id: 10, label: "Card 10", image: bday10 },
  { id: 11, label: "Card 11", image: bday11 },
  { id: 12, label: "Card 12", image: bday12 },
  { id: 13, label: "Card 13", image: bday13 },
  { id: 14, label: "Card 14", image: bday14 },
  { id: 15, label: "Card 15", image: bday15 },
  { id: 16, label: "Card 16", image: bday16 },
  { id: 17, label: "Card 17", image: bday17 },
];


// Gandhiji & Lal Bahadur Shastri Jayanti Celebration 2023
const gandhiji=[
  { id: 1, label: "Card 1", image:gandhi1},
  { id: 2, label: "Card 2", image:gandhi2},
  { id: 3, label: "Card 3", image:gandhi3},
  { id: 4, label: "Card 4", image:gandhi4},
  { id: 5, label: "Card 5", image:gandhi5},
]


//cross race

const race=[
  { id: 1, label: "Card 1", image:race1},
  { id: 2, label: "Card 2", image:race2},
  { id: 3, label: "Card 3", image:race3},
  { id: 4, label: "Card 4", image:race4},
  { id: 5, label: "Card 5", image:race5},
  { id: 6, label: "Card 6", image:race6},
  { id: 7, label: "Card 7", image:race7},
  { id: 8, label: "Card 8", image:race8},
  { id: 9, label: "Card 9", image:race9},
  { id: 10, label: "Card 10", image:race10},
  { id: 11, label: "Card 11", image:race11},
  { id: 12, label: "Card 12", image:race12},
]

// Hindi Diwas Celebration 2023

const hindhi=[
  { id: 1, label: "Card 1", image:hindhi1},
  { id: 2, label: "Card 2", image:hindhi2},
  { id: 3, label: "Card 3", image:hindhi3},
  { id: 4, label: "Card 4", image:hindhi4},
  { id: 5, label: "Card 5", image:hindhi5},
  { id: 6, label: "Card 6", image:hindhi_6},
  { id: 7, label: "Card 7", image:hindhi7},
  { id: 8, label: "Card 8", image:hindhi8},
  // { id: 9, label: "Card 9", image:hindhi9},
  // { id: 10, label: "Card 10", image:hindhi10},
]

//independce day
 const iday=[
  { id: 1, label: "card1", image:iday_1},
  { id: 2, label: "card2", image:iday_2},
 ]

//raksha bandha

const raksha=[
  { id: 1, label: "card1", image:raksha_1},
  { id: 2, label: "card2", image:raksha_2},
  { id: 3, label: "card3", image:raksha_3},
  { id: 4, label: "card4", image:raksha_4},
  { id: 5, label: "card5", image:raksha_5},
]

//teachers day

const teacher=[
  { id: 1, label: "card1", image:teach_1},
  { id: 2, label: "card2", image:teach_2},
  { id: 3, label: "card3", image:teach_3},
  { id: 4, label: "card4", image:teach_4},
  { id: 5, label: "card5", image:teach_5},
  { id: 6, label: "card6", image:teach_6},
  { id: 7, label: "card7", image:teach_7},
  { id: 8, label: "card8", image:teach_8},
  { id: 9, label: "card9", image:teach_9},
  { id: 10, label: "card10", image:teach_10},
]


//kannada rajyotsava

const  rajyotsava=[
  { id: 1, label: "card1", image:raj1},
  { id: 2, label: "card2", image:raj2},
  { id: 3, label: "card3", image:raj3},
  { id: 4, label: "card4", image:raj4},
  
]



  const user = JSON.parse(sessionStorage.getItem("user"));

  const headers = {
    "Content-type": "application/json",
    Authorization:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbjEiLCJpYXQiOjE2NDgwMzE5OTUsImV4cCI6MTY2MzU4Mzk5NX0.h3uex4gcdMAA8cOMN_mqFbxlYhZRtFkQMLoke7y-8dXL0-oBy41qmWhkIJ-O0bNlaLcdHYxKygm3GHdd84bMVw",
  };

  const ImageUrl = `https://executivetracking.cloudjiffy.net/SRPUSchoolWeb/file/downloadFile/?filePath=`;

  const baseUrl = "https://executivetracking.cloudjiffy.net/SRPUSchoolWeb";

  const FetchData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}/gallery/v1/getAllGalleryByPagination/{pageNumber}/{pageSize}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data;
        setGallery(data.content);
        console.log("gallery===", data.content);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  const GalleryData = async (eventTypeId) => {
    console.log("evenId", eventTypeId);
    await axios({
      method: "get",
      url: `${baseUrl}/gallery/v1/getGallerysByPaginationEventType/{pageNumber}/{pageSize}/{eventTypeId}?eventTypeId=${eventTypeId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data.content;
        setGallery(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const EventData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}/eventtype/v1/getAllEventType`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data;
        setEventData(data);
        console.log("eventData==", data);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchData();
    EventData();
  }, [eventTypeId]);

  return (
    <div>
      <NavBar />
      {/* <!------- End of Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Media</li>
          </ol>
          <h2>Gallery</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      <section id="portfolio" class="portfolio">
        <div class="container">
          <header class="section-header">
            <Card className="text-center">
              <Card.Header>
                <h4>Gallery</h4>
              </Card.Header>
            </Card>
          </header>
          <div>
            <h2 className="text-center">Lakshmi Pooja and Havana</h2>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={image1} alt="Image 1" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={image2} alt="Image 2" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={image4} alt="Image 3" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={image5} alt="Image 4" />
                </Card>
              </Col>
            </Row>
          </div>
          <div>
            <h2 className="text-center">World Environment Day 2023</h2>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={enve1} alt="Image 1" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={enve2} alt="Image 2" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={enve3} alt="Image 3" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={enve4} alt="Image 4" />
                </Card>
              </Col>
            </Row>

            <div className="card-container">
              <Row className="justify-content-center">
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={enve5} alt="Image 3" />
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={enve6} alt="Image 3" />
                  </Card>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={enve7} alt="Image 3" />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <h2 className="text-center">
              {" "}
              Saraswati Pooja and Orientation Program
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {group.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              {" "}
              World Yoga Day 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {yogaGroup.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center"> Freshers Day 2023</h2>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fre1} alt="Image 1" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fre2} alt="Image 2" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fre3} alt="Image 3" />
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card>
                  <Card.Img variant="top" src={fre4} alt="Image 4" />
                </Card>
              </Col>
            </Row>

            <div className="card-container">
              <Row className="justify-content-center">
                {/* <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={fre5} alt="Image 3" />
                  </Card>
                </Col> */}
                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={fre6} alt="Image 3" />
                  </Card>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <Card>
                    <Card.Img variant="top" src={fre7} alt="Image 3" />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <h2 className="text-center">
              123rd Birth Anniversary of Shri Sanganabasava Swamiji
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {swamjiBirthday.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
              Gandhiji & Lal Bahadur Shastri Jayanti Celebration 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {gandhiji.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
            Cross Country Race 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {race.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

           <div>
            <h2 className="text-center">
            Hindi Diwas Celebration 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {hindhi.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
            Independance Day 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {iday.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
            Raksha Bhandhan 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {raksha.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div>
            <h2 className="text-center">
            Teachers Day Celebration 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {teacher.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
       
          <div>
            <h2 className="text-center">
            kannada rajyotsava Celebration 2023
            </h2>

            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
              {rajyotsava.map((card) => (
                <Col key={card.id}>
                  <Card>
                    <Card.Img variant="top" src={card.image} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>



          <div class="row">
            <div class="col-lg-12 d-flex justify-content-center">
              <div className="event-card m-2">
                <Button
                  className="m-2"
                  class=" rounded-pill m-1 button-event"
                  onClick={(e) => FetchData()}
                >
                  {"All"}
                </Button>
                {eventData.map((data, index) => (
                  <Button
                    className="m-2"
                    class="btn btn-primary rounded-pill m-1"
                    key={index}
                    onClick={(e) => GalleryData(data.eventTypeId)}
                  >
                    {data.eventTypeName}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <div class="row row-cols-1 row-cols-md-3 g-4">
            {gallery.map((item, index) => {
              return (
                <div class="col">
                  <div class="card">
                    <img
                      src={ImageUrl + encodeURIComponent(item.galleryPicPath)}
                      class="img-fluid"
                      alt="Gallery"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default Gallery;
