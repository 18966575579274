import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import CollegeVideo from "../../../assets/img/video/CollegeVideo.mp4";
import "./LandingHome.css";
// import { Carousel } from 'react-bootstrap';
// import slider1 from '../../../images/slider3.jpg';
// import image from "../../../images/unnamed.jpg";
// import image1 from "../../../images/slider2png.png";
// import image2 from "../../../images/slider3png.png";
// import image1 from '../../../images/01-SUNIL K SHIVANAGI.jpg';
// import image2 from '../../../images/02-PRAJWAL S BANDI.jpg';
// import { useState } from "react";
// import Slider from "react-slick";
// import Carousel from "nuka-carousel";
import newslider1 from "../../../images/newslider1.jpg";
import newslider2 from "../../../images/newslider2.jpg";
import newslider3 from "../../../images/banner akash.jpg";
import newslider4 from "../../../images/sliderPoster -3.jpg";

const LandingHome = () => {
  return (
    <div>
      {/* <div className="player-wrapper">
        <ReactPlayer
          url={CollegeVideo}
          className="react-player"
          playing
          width="100%"
          height="100%"
          muted
        />
      </div> */}

      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        transitionTime={500}
        showArrows={true}
        showThumbs={false}
        centerMode={false}
        showIndicators={true}
        dynamicHeight={true}
        height={1000}
      >
        <div>
          <img src={newslider1} alt="" className="carousel-image" />
        </div>
        <div>
          <img src={newslider2} alt="" className="carousel-image" />
        </div>
        <div>
          <img src={newslider3} alt="" className="carousel-image" />
        </div>
        <div>
          <img src={newslider4} alt="" className="carousel-image" />
        </div>
        {/* <div>
        <img src={image2} alt="" />
      </div> */}
      </Carousel>

      <div></div>
    </div>
  );
};

export default LandingHome;
